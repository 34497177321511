<template>
  <a-form
    name="custom-validation"
    ref="formRef"
    layout="vertical"
    :model="formState"
    @finish="handleFinish"
    @finishFailed="handleFinishFailed"
  >
    <a-row>
      <a-col :span="12">
        <a-form-item label="" name="name">
          <div class="my-title">身份证扫描件(<span class="red">正反面2张</span>)</div>
          <my-upload :obj="{ id:1, fileList: formState.cards, itemArrFun: fileListFun, size:2, name:'身份证', url:'uploadUser', type: 'user', fileSize: 5, accept: '.jpg,.jpeg,.png,.dds,.psd,.pdt,.gif,.svg' }" />
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item label="" name="name">
          <div class="my-title">营业执照副本</div>
          <my-upload :obj="{ id:2, fileList: formState.license, itemArrFun: fileListFun, size:3, name:'营业执照', url:'uploadUser', type: 'user', fileSize: 5, accept: '.jpg,.jpeg,.png,.dds,.psd,.pdt,.gif,.svg,.pdf' }" />
        </a-form-item>
      </a-col>
      <a-col :span="18">
        <a-form-item label="其他相关资料" >
          <my-upload :obj="{ id:3, fileList: formState.qualifications, itemArrFun: fileListFun, size:3, name:'其他', url:'uploadUser', type: 'user', fileSize: 5, accept: '.jpg,.jpeg,.png,.dds,.psd,.pdt,.gif,.svg' }" />
        </a-form-item>
      </a-col>
    </a-row>
    <a-form-item >
      <a-button type="primary" html-type="submit" :disabled="loadingFlag">更新认证设置</a-button>
    </a-form-item>
  </a-form>
</template>
<script>
import { defineComponent, reactive, ref, onMounted, toRaw } from 'vue'
import MyUpload from '../../components/smallParts/MyUpload.vue'
import { getUserInfo, insertPic } from '../../api'
import { message } from 'ant-design-vue'
export default defineComponent({
  components: {
    MyUpload
  },
  setup() {
    const formRef = ref()
    const formState = reactive({
      pid: null,
      cards: [],
      license: [],
      qualifications: []
    })
    const loadingFlag = ref(false)
    const stringToArray = (str) => {
      const list = str.split(',')
      const arr = []
      list.forEach((item, i) => {
        arr.push({
          uid: i,
          status: 'done',
          url: item,
          name: 'image.png'
        })
      })
      return arr
    }
    const arrayToString = (arr) => {
      const obj = {
        cards: '',
        license: '',
        qualifications: ''
      }
      for (const key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) {
          arr[key].forEach((item, i) => {
            if (i === 0) obj[key] += item.url
            else obj[key] += ',' + item.url
          })
        }
      }
      return obj
    }
    const handleFinish = async () => {
      if (formState.cards.length === 2 && formState.license.length === 1) {
        loadingFlag.value = true
        const data = arrayToString(toRaw(formState))
        const userID = window.sessionStorage.getItem('user-id')
        const res = await insertPic({ ...data, status: 1, pid: formState.pid, userId: userID })
        window.sessionStorage.setItem('user-status', 1)
        loadingFlag.value = false
        message.success('更新成功')
      } else {
        message.error('请按照标题上传内容！')
      }
    }

    const handleFinishFailed = errors => {
      console.log(errors)
    }

    const resetForm = () => {
      formRef.value.resetFields()
    }
    const fileListFun = (id, arr) => {
      if (id === 1) {
        formState.cards = arr
      } else if (id === 2) {
        formState.license = arr
      } else if (id === 3) {
        formState.qualifications = arr
      }
    }
    const init = async () => {
      const id = window.sessionStorage.getItem('user-id')
      const res = await getUserInfo(id)
      let { picInfo } = res
      if (picInfo) {
        if (picInfo.cards) {
          formState.pid = picInfo.pid
          formState.cards = stringToArray(picInfo.cards)
        }
        if (picInfo.license) {
          formState.license = stringToArray(picInfo.license)
        }
        if (picInfo.qualifications) {
          formState.qualifications = stringToArray(picInfo.qualifications)
        }
      } else {
        formState.cards = []
        formState.license = []
        formState.qualifications = []
      }
    }
    onMounted(init)

    return {
      formState,
      formRef,
      handleFinishFailed,
      handleFinish,
      resetForm,
      fileListFun,
      loadingFlag
    }
  }
})
</script>

<style lang="less" scoped>
.my-title{
  padding-bottom: 8px;
  vertical-align: middle;
}
.my-title::before{
  content: '*';
  color: red;
  font-weight: 600;
  font-size: 16px;
  vertical-align: middle;
  margin-right: 4px;
}
.red{
  color: red;
  font-weight: 600;
}
</style>